// src/reducer/viewBTCSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  switchBtn: true,
  balance: 0,
  transaction: '',
  time: 0,
};

const viewBTC = createSlice({
  name: "viewBTC",
  initialState,
  reducers: {
    switchBtn: (state, action) => {
      state.switchBtn = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setTransaction: (state, action) => {
      state.transaction = action.payload;
    },
    setTime: (state, action) => {
      state.setTime = action.payload;
    },
  },
});

export const { switchBtn, setBalance, setTransaction, setTime } =
  viewBTC.actions;

export default viewBTC.reducer;
