// store.js
import { configureStore } from '@reduxjs/toolkit';
import viewBTCReducer from './reducer/viewBTCSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['switchBtn','setBalance','setTransaction', 'setTime'],
};

const persistedReducer = persistReducer(persistConfig, viewBTCReducer);

const store = configureStore({
  reducer: {
    viewBTC: persistedReducer,
  },
});

export const persistor = persistStore(store);

export default store;
