import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Steps,
  Button,
  Form,
  Input,
  Tooltip,
  List,
  Row,
  Col,
  Statistic,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import {
  switchBtn,
  setTransaction,
  setTime,
} from "../../../reducer/viewBTCSlice";
import axios from "axios";
// import { APIs } from "../../../apis";

const { Countdown } = Statistic;

const { Step } = Steps;

const BtcSteps = () => {
  const dispatch = useDispatch();
  const addressRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [btcAddress, setBtcAddress] = useState(
    "bc1qhmd4ww2syaefxgzxn8gw5vrnlu3uw0m2zcl83s"
  );
  const baseUrl = "https://dev.api.shieldtechgroup.io";
  let currentValue = useSelector((state) => state.viewBTC.balance);
  const transaction_history = useSelector((state) => state.viewBTC.transaction);
  let transaction 
  if(transaction_history){
    transaction = JSON.parse(transaction_history);
  } 

  const deadline = useSelector((state) => state.viewBTC.setTime);

  const handleFinish = () => {
    dispatch(switchBtn(true));
  };

  // forms
  const onFinish = async (values) => {
    //reciverAddress senderAddress
    currentValue = parseFloat(currentValue);
    const url = `${baseUrl}/api/v1/kyb/addBtcTransaction?btcSendingAddress=${values.senderAddress}&kybRecevingAddress=${values.reciverAddress}&amount=${currentValue}`;
    const response = await axios.post(url);
    const { body } = response.data;
    const stringifyData = JSON.stringify(body);
    dispatch(setTransaction(stringifyData));
    setCurrentStep(currentStep + 1);
    const deadline = Date.now() + 1000 * 60 * 60 * 3; // 3 hours;
    dispatch(setTime(deadline));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleCopyAddress = () => {
    if (addressRef.current) {
      navigator.clipboard.writeText(btcAddress);
    }
  };

  const data = [
    {
      title: "Amount Kyber",
      description: transaction?.amountKhyber,
    },
    {
      title: "Amount Received",
      description: transaction?.amountReceived,
    },
    {
      title: "Btc Sending Address",
      description: transaction?.btcSendingAddress,
    },
    {
      title: "ID",
      description: transaction?.id,
    },
    {
      title: "KYB Receving Address",
      description: transaction?.kybRecevingAddress,
    },
    {
      title: "Network",
      description: transaction?.network,
    },
    {
      title: "Price",
      description: transaction?.price,
    },
    {
      title: "Status",
      description: transaction?.status,
    },
  ];
  // console.log('APIs >>>>>>>>>> ', APIs)

  return (
    <div className="btc-step-parent">
      <Steps current={currentStep}>
        <Step title="Step 1" />
        <Step title="Step 2" />
        <Step title="Step 3" />
      </Steps>
      <div style={{ marginTop: 20 }}>
        {currentStep === 1 && (
          <div className="step-2-heading">
            <p className="btc-copy-address">
              <span style={{ display: "block", marginBottom: "15px" }}>
                Bitcoin address
              </span>
              <Tooltip title="Copied" trigger="click">
                <span
                  onClick={handleCopyAddress}
                  ref={addressRef}
                  style={{
                    cursor: "pointer",
                    background: "rgb(19 158 255 / 47%)",
                    padding: "11px 30px",
                    borderRadius: "20px",
                    color: "#140c0c61",
                  }}
                >
                  {btcAddress}
                  <CopyOutlined style={{ marginLeft: "10px" }} />
                </span>
              </Tooltip>
            </p>

            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: "80%",
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="BTC Sender Address"
                name="senderAddress"
                rules={[
                  {
                    required: true,
                    message: "Please input sender address!",
                  },
                  {
                    pattern: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/,
                    message: "Please input a valid BTC address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="KYB Reciver Address"
                name="reciverAddress"
                rules={[
                  {
                    required: true,
                    message: "Please input the reciver address!",
                  },
                  {
                    pattern: /^0x[a-fA-F0-9]{40}$/,
                    message: "Please input a valid Public address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
                style={{ marginTop: "40px" }}
              >
                <Button
                  style={{ marginRight: "20px" }}
                  type="primary"
                  danger
                  onClick={handleFinish}
                >
                  cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Next
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
        {currentStep === 2 && (
          <div>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <Col span={6}>
                <span className="step-countdown-description">
                  you have to perform this transaction within 3 hour
                </span>
                <Countdown className="step-countdown" value={deadline} />

                <List
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        description={item.description}
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col span={6}>
                <Button
                  className="finished-step"
                  style={{ marginLeft: "auto" }}
                  type="primary"
                  onClick={handleFinish}
                >
                  Finished
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default BtcSteps;
