import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Select from "react-select";
import { parseEther } from "viem";
import { useContractWrite, useAccount, useWaitForTransaction } from "wagmi";
import { ERC_20, ETH_ADDRESS } from "../contract/addresses";
import ethABI from "../contract/ethABI.json";
import erc20ABI from "../contract/ERC20ABI";
import { createStandaloneToast } from "@chakra-ui/toast";
import axios from "axios";
import { switchBtn, setBalance } from "../../../reducer/viewBTCSlice";
import EthIcon from "./../assets/eth1.svg";
import USDTIcon from "./../assets/usdt.svg";
import BNBIcon from "./../assets/bnb.svg";
import FTMIcon from "./../assets/ftm.svg";
import WBTCIcon from "./../assets/wbtc.svg";

const { toast } = createStandaloneToast();

const options = [
  { value: "ethereum", label: "Eth" },
  { value: "usdt", label: "USDT" },
  { value: "bitcoin", label: "BTC" },
];

const icons = {
  eth: EthIcon,
  usdt: USDTIcon,
  bnb: BNBIcon,
  ftm: FTMIcon,
  wbtc: WBTCIcon,
};

const Calculator = () => {
  const dispatch = useDispatch();
  const { isConnected } = useAccount();
  const [selectedOption, setSelectedOption] = useState({
    value: "ethereum",
    label: "Eth",
  });
  const [tokenValue, setTokenValue] = useState(0);
  const [tokenToKYB, setTokenToKYB] = useState(0);
  const kybToUsdtRate = 0.15;

  const {
    isLoading,
    write: depositEth,
    error,
    isSuccess,
  } = useContractWrite({
    address: ETH_ADDRESS,
    abi: ethABI,
    functionName: "depositEth",
  });

  const {
    isLoading: approveLoading,
    write: approveTokens,
    error: approveError,
    data: approveData,
  } = useContractWrite({
    address: ERC_20, // make address object chain?.id,
    abi: erc20ABI,
    functionName: "approve",
  });

  const notify = ({ status, description, title }) => {
    toast({
      duration: 2500,
      status,
      title,
      description,
    });
  };

  const {
    isLoading: depositLoading,
    write: depositOther,
    error: otherError,
    isSuccess: otherSuccess,
  } = useContractWrite({
    address: ETH_ADDRESS,
    abi: ethABI,
    functionName: "depositToken",
  });

  const Control = ({ children, ...props }) => {
    const selected = props.getValue()?.[0];
    return (
      <div className="select">
        <img className="icon" src={icons[selected?.value]} /> {children}
      </div>
    );
  };

  const CustomOption = ({ children, innerProps, isDisabled, data }) => {
    return !isDisabled ? (
      <div {...innerProps} className="option">
        <img className="icon" src={icons[data?.value]} />
        {children}
      </div>
    ) : null;
  };

  const calculateReceivedKyb = () => {
    let i = 1;
    return tokenToKYB;
  };

  const calculateUsdtAmount = () => {
    // const fetchData = async (data) => {
    //   try {
    //     let jsonData = await axios.get(data);
    //     // setData(jsonData);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };

    // fetchData("https://dev.api.shieldtechgroup.io/kybToUSD");
    return kybToUsdtRate.toFixed(4);
  };

  const handleBuyToken = () => {
    if (selectedOption?.value === "ethereum") {
      depositEth({
        value: parseEther(tokenValue),
      });
    }
    else {
      approveTokens({
        args: [ETH_ADDRESS, parseEther(tokenValue)],
      });
    }
  };
  const handleBuyBTC =  () => {
    dispatch(setBalance(tokenValue));
    dispatch(switchBtn(false));
  };

  const { isSuccess: waitSuccess, isLoading: waitLoading } =
    useWaitForTransaction({
      hash: approveData?.hash,
    });

  useEffect(() => {
    if (error?.message || approveError?.message || otherError?.message)
      notify({
        status: "error",
        title: error?.message || approveError?.message || otherError?.message,
      });
  }, [error?.message, approveError?.message, otherError?.message]);

  useEffect(() => {
    if (isSuccess || otherSuccess)
      notify({
        status: "success",
        title: "Token buy successfully!",
      });
  }, [isSuccess, otherSuccess]);

  useEffect(() => {
    if (waitSuccess) {
      console.log("depositOther");
      depositOther({
        args: [ERC_20, parseEther(tokenValue)],
      });
    }
  }, [waitSuccess]);

  const fetchData = async () => {
    try {
      if (selectedOption) {
        let tokenName, networkName;
        if (selectedOption.value === "ethereum") {
          tokenName = "eth";
          networkName = "ethereum";
        } else if (selectedOption.value === "usdt") {
          tokenName = "usdt";
          networkName = "ethereum";
        } else if (selectedOption.value === "bitcoin") {
          tokenName = "btc";
          networkName = "bitcoin";
        }
        const url = `https://dev.api.shieldtechgroup.io/api/v1/kyb/tokenToKYB?amount=${tokenValue}&tokenName=${tokenName}&network=${networkName}`;
        const response = await axios.get(url);
        setTokenToKYB(Number(response.data.body).toFixed(4));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedOption, tokenValue]);

  const handleChange = (selectedValue) => {
    setSelectedOption(selectedValue);
  };

  return (
    <>
      <div className="data-row">
        <input
          className="input calcInput"
          value={tokenValue}
          placeholder="Enter Amount Want to Send"
          onChange={(e) => setTokenValue(e.target.value)}
          type="number"
        />
        <Select
          onChange={handleChange}
          options={options}
          value={selectedOption}
          components={{ Control, Option: CustomOption }}
        />
      </div>
      <div className="token-calculator-card address">
        <h3>
          <b className="underline">Token Calculator</b>
        </h3>
        <div>
          <p className="calculatorText">
            {`1 KYB = ${calculateUsdtAmount()} USDT`}
          </p>
          <p className="calculatorTextSending">
            {`Send ${tokenValue}  ${(selectedOption.value
              ? selectedOption.value
              : selectedOption
            ).toUpperCase()} and receive ${calculateReceivedKyb()} KYB`}
          </p>
        </div>
      </div>
      {selectedOption.value === "bitcoin" ? (
        <button
          disabled={!depositEth || !tokenValue}
          className="btn btn2"
          onClick={handleBuyBTC}
        >
          {isLoading || depositLoading || approveLoading || waitLoading
            ? "Check Wallet"
            : ".Buy Tokens"}
        </button>
      ) : (
        <button
          disabled={
            !depositEth || !tokenValue || !selectedOption || !isConnected
          }
          className="btn btn2"
          onClick={handleBuyToken}
        >
          {isLoading || depositLoading || approveLoading || waitLoading
            ? "Check Wallet"
            : "Buy Tokens"}
        </button>
      )}
    </>
  );
};

export default Calculator;
