/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { createStandaloneToast } from "@chakra-ui/toast";
import { FiCopy } from "react-icons/fi"; // Import copy icon from react-icons library
import { useSelector } from 'react-redux';

import BtcSteps from "./btc-steps";
import Calculator from "./calculater";

const { ToastContainer } = createStandaloneToast();

export default function BuyToken() {
  // const [kybAmount, setKybAmount] = useState(0.0001);
  const kybToUsdtRate = 0.15;
  const usdtToKybRate = 1 / kybToUsdtRate;
  const [copiedText, setCopiedText] = useState("");

  const viewBTC = useSelector(state=> state.viewBTC.switchBtn)
  // Function to copy text to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text); // Set copied text for indication

    // Clear the copied text after 3 seconds
    setTimeout(() => {
      setCopiedText("");
    }, 3000);
  };
  useEffect(()=>{
    console.log('typeOf', typeof(viewBTC) ,'global checkview BTC  >>>>>', viewBTC);
  },[])

  return (
    <>
      {viewBTC ? <Calculator /> : <BtcSteps />}
      <div className="container">
        <h5 className="kyberUl-heading">
          Import / Add KyberUltra Network in your Wallet
        </h5>
        <div className="card">
          <div className="info">
            <p>
              <strong>Name:</strong> KyberUltra
              <button
                className="copy-button"
                onClick={() => copyToClipboard("KyberUltra")}
              >
                <FiCopy className="fiCopy" />
              </button>
            </p>
            <p>
              <strong>RPC URL: </strong> https://mainnet-rpc.shieldtechgroup.is
              <button
                className="copy-button"
                onClick={() =>
                  copyToClipboard("https://mainnet-rpc.shieldtechgroup.is")
                }
              >
                <FiCopy className="fiCopy" />
              </button>
              {/* </a> */}
            </p>
            <p>
              <strong>ChainId:</strong> 1080
              <button
                className="copy-button"
                onClick={() => copyToClipboard("1080")}
              >
                <FiCopy className="fiCopy" />
              </button>
            </p>
            <p>
              <strong>Currency:</strong> KYB
              <button
                className="copy-button"
                onClick={() => copyToClipboard("KYB")}
              >
                <FiCopy className="fiCopy" />
              </button>
            </p>
            <p>
              <strong>Explorer:</strong> https://kyberscan.shieldtechgroup.is
              <button
                className="copy-button"
                onClick={() =>
                  copyToClipboard("https://kyberscan.shieldtechgroup.is")
                }
              >
                <FiCopy className="fiCopy" />
              </button>
            </p>
          </div>
        </div>
        {copiedText && <p>Copied: {copiedText}</p>}
      </div>

      <ToastContainer />
    </>
  );
}
